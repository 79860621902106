import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToMinuteSeconds',
})

export class SecondsToMinuteSeconds implements PipeTransform {
  constructor() {}

  transform(value) {
    if(value == undefined || parseInt(value) == 0) {
      return '00:00';
    }

    let mins = Math.trunc(value / 60);
    let secs = value % 60;
    return `${mins}:${this.secSeconds(secs)}`;
  }

  private secSeconds(secs) {
    return secs < 10 ? `0${secs}` : secs ;
  }
}
