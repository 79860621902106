interface AndroidWebViewInterface {
  sendMessage(message: string): void;
}

declare global {
  interface Window {
    Android: AndroidWebViewInterface;
  }
}

export default {};
